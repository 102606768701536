/* ArticleCard.css */

.fixed-size-card {
    height: 350px; /* Adjust the height as needed */
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .fixed-size-card .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .fixed-size-card .card-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .fixed-size-card .card-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
    line-clamp: 4;
    box-orient: vertical;
  }
  
  .fixed-size-card .d-flex {
    margin-top: 1rem;
  }
  