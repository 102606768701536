.article-view {
    max-width: 700px;
    margin: auto;
  }
  
  .article-content {
    margin-bottom: 1rem;
  }
  
  .article-title {
    font-size: 1.5rem;
  }
  
  .article-subtitle {
    margin-bottom: 0.5rem;
  }
  
  .article-body {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }
  
  .article-actions {
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    padding: 0.5rem 0;
  }
  
  .article-actions .btn {
    display: flex;
    align-items: center;
  }
  
  .article-actions .btn i {
    margin-right: 0.5rem;
  }
  
  .comments-section h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .comments-section .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
  
  .comments-section .form-control {
    resize: none;
  }
  