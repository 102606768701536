.form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-group .input-group-text {
    border-right: 0;
    background-color: #f8f9fa;
}

.input-group .form-control {
    border-left: 0;
}
