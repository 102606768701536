/* src/components/EventCard.css */

.card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .fixed-size-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;
  }
  
  .fixed-size-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
  }
  
  .card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    flex-shrink: 0;
  }
  
  .card-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
    line-clamp: 4;
    box-orient: vertical;
  }
  
  .event-details .d-flex {
    margin-bottom: 5px;
  }
  
  .btn-success {
    background-color: #28a745;
    color: white;
    width: 100%;
    text-align: center;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  