.App {
  text-align: justify;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a3c40; /* Very dark green */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar .navbar-brand img {
  margin-right: 10px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #5a7d7e; /* Lighter shade of green for hover */
}

.navbar-light .navbar-nav .btn-link {
  color: black;
}

.navbar-light .navbar-nav .btn-link:hover {
  color: #5a7d7e; /* Lighter shade of green for hover */
}

.navbar-top {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-top {
  background-color: white; /* White for the top part */
}

.navbar-second {
  background-color: #1a3c40; /* Very dark green for the second row */
}

.navbar-third {
  background-color: #d3d3d3; /* Grey for the third row */
}

.navbar-second .nav-item,
.navbar-third .nav-item {
  margin-right: 10px;
}

.navbar-second .nav-link,
.navbar-third .nav-link {
  color: white;
}

.navbar-second .nav-link:hover,
.navbar-third .nav-link:hover {
  color: #cce7e8; /* Lighter shade of green for hover */
}

.d-none {
  display: none;
}

/* styles.css */

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}


.dropdown-menu {
  background-color: white;
  border: 0px solid #ddd;
}

.dropdown-item {
  color: black;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}