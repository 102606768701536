/* src/components/EventView.css */
.container {
    margin-top: 20px;
}

.card {
    padding: 20px;
    margin-bottom: 20px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.card-text {
    margin-bottom: 20px;
}

.event-details .d-flex {
    margin-bottom: 10px;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.waitlist {
    margin-top: 30px;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-group-item button {
    margin-left: 10px;
}
